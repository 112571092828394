import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/": [~6],
		"/changelog": [20],
		"/(app)/(authorized)/collections": [~7,[2],[3]],
		"/(app)/(authorized)/collections/[collectionId]": [~8,[2],[3]],
		"/(email-tests)/emails/quote-request-created": [~18,[5]],
		"/(email-tests)/emails/share": [~19,[5]],
		"/(app)/help": [11,[2],[3]],
		"/(app)/locations/[country=country]": [~13,[2],[3]],
		"/(app)/locations/[slug]": [~14,[2],[3]],
		"/(app)/locations/[...rest]": [~12,[2],[3]],
		"/(app)/(authorized)/plan": [9,[2],[3]],
		"/(app)/(authorized)/profile": [10,[2],[3]],
		"/(auth)/reset-password": [15,[4]],
		"/(auth)/signin": [16,[4]],
		"/(auth)/signup": [17,[4]],
		"/widgets": [~21]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';