import type { HandleClientError, NavigationEvent } from "@sveltejs/kit";
import * as Sentry from "@sentry/sveltekit";

const clientHandleError = (({ error, event }: { error: Error; event: NavigationEvent }) => {
  // captureClientException(error, event);
  return {
    message: error.message ?? "An unknown client error occurred",
  };
}) as HandleClientError;

export const handleError = Sentry.handleErrorWithSentry(clientHandleError);
